<template>
  <div>
    <div class="header">
      <h4>财务功能</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8" class="item" v-access data-ctrl="trade.rechargeRecords">
        <el-card shadow="always" v-prev path="/system/trade/recharge">
          <el-button size="medium" type="text"> 查看充值记录</el-button>
        </el-card>
      </el-col>
      <el-col :span="8" class="item" v-access data-ctrl="trade.tradeRecords">
        <el-card shadow="always" v-prev path="/system/trade/list">
          <el-button size="medium" type="text"> 交易管理</el-button>
        </el-card>
      </el-col>
      <el-col
        :span="8"
        class="item"
        v-access
        data-ctrl="trade.withdrawalRecords"
      >
        <el-card shadow="always" v-prev path="/system/withdraw/list">
          <el-button size="medium" type="text"> 提现管理</el-button>
        </el-card>
      </el-col>
      <el-col :span="8" class="item" v-access data-ctrl="trade.makerPayStatics">
        <el-card shadow="always" v-prev path="/system/trade/userStatics">
          <el-button size="medium" type="text">创客支付统计</el-button>
        </el-card>
      </el-col>
      <el-col
        :span="8"
        class="item"
        v-access
        data-ctrl="trade.enterprisePayStatics"
      >
        <el-card shadow="always" v-prev path="/system/trade/enterpriseStatics">
          <el-button size="medium" type="text">企业支付统计</el-button>
        </el-card>
      </el-col>
    </el-row>
    <!-- <div class="header">
      <h4>财务统计</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span>卡片名称</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >刷新</el-button
            >
          </div>
          统计图1
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span>卡片名称</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >刷新</el-button
            >
          </div>
          统计图1
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span>卡片名称</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >刷新</el-button
            >
          </div>
          统计图1
        </el-card>
      </el-col>
    </el-row> -->

    <div class="header">
      <h4>帮助文档</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8">
        <el-card shadow="always">
          <el-button size="medium" type="text">文档1</el-button>
        </el-card>
      </el-col>
      <el-col :span="8">
        <a
          href="/content/kpce6tnz0/document.html?skin=document"
          target="_blank"
        >
          <el-card shadow="always">
            <el-button size="medium" type="text">文档2</el-button>
          </el-card>
        </a>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
<style scoped>
.item {
  margin-bottom: 10px;
}
</style>
